<template>
  <a-modal width="50rem" class="forgot-password-modal" @cancel="closeModal">
    <div class="logo-box">
      <img
          class="logo"
          src="../../assets/images/Amblem2@2x.webp"
          alt="Glocal Logo"
      />
    </div>
    <div class="content">
      <h1 class="content__heading">Forgot Your Password?</h1>
      <p class="content__para">
        Enter your email address below to reset your password
      </p>
      <form class="form" @submit.prevent="sendResetPasswordLink">
        <div class="form-fields">
          <input
              type="email"
              class="form-text"
              required
              placeholder="Email Address"
              v-model.trim="email"
          />
          <p v-if="error" class="error">{{ error }}</p>
          <p v-else class="success-msg">{{ message }}</p>
          <a-button
            @click="sendResetPasswordLink()"
            class="form-btn btn-primary"
            :loading="loading"
          >
            Reset Password
          </a-button>
        </div>
      </form>
    </div>
  </a-modal>
</template>

<script>
import ApiService from "../../services/api.service.js";

export default {
  data() {
    return {
      email: "",
      error: "",
      message: "",
      loading: false,
    };
  },
  methods: {
    async sendResetPasswordLink() {
      if(this.email === ""){
        this.error = "This field is required";
        return false;
      }
      this.loading = true;
      this.error = "";
      ApiService.removeHeader();
      await ApiService.post("user/password-reset/", {email: this.email})
          .then((response) => {
            if (response.status === 200) {
              this.message =
                  "Email sent successfully. Use the link sent in your email to reset your password";
            }
          })
          .catch((err) => {
            this.error = err.response.data.non_field_errors[0]
          });
      this.loading = false;
    },
    closeModal() {
      this.email = '';
      this.error = '';
      this.message = '';
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.forgot-password-modal {
  .ant-modal-content {
    .ant-modal-close {
      margin: 3rem 2.5rem 0 0 !important;
      .ant-modal-close-x {
        padding: 0.5rem;
        width: fit-content;
        height: fit-content;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
          color: $color-black;
          font-size: 2rem;
        }
      }
    }
    .ant-modal-body {
      padding: 0;

      .logo-box {
        width: 3.2rem;
        display: block;
        margin: auto;
        padding-top: 3.6rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      .content {
        padding: 0 9rem 10rem;

        &__heading {
          text-align: center;
          font-size: 2.6rem;
          font-family: $font-primary-bold;
          padding-top: 1.9rem;
        }

        &__para {
          text-align: center;
          margin-bottom: 2.4rem;
          font-size: 1.6rem;
          font-family: $font-primary;
          padding: 0 2rem;
        }

        .form-fields {
          display: flex;
          flex-direction: column;

          .form-text {
            height: 4.5rem;
            border: 1px solid #24242433;
            margin-bottom: 1.1rem;
            padding: 1.6rem;
            outline-color: $color-primary;
            font-size: 1.6rem;
            font-family: $font-primary;
          }

          .form-btn {
            font-family: "Roboto-bold";
            font-size: 1.6rem;
            height: 4.5rem;
            border: none;
            margin-top: 1rem;
          }
          .success-msg,
          .error {
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
</style>
